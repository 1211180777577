import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = ()=> {
    return {
        border: true,
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        editBtn: false,
        delBtn: false,
        align: 'center',
        addBtn: false,
        viewBtn: false,
        height: 'auto',
        menuWidth: 140,
        columnBtn: false,
        refreshBtn: false,
        menu:false,
        column: [{
            width:100,
            label: t('withdrawNft.name9'),
            prop: 'applyId'
        }, {
            width:100,
            label: t('currencySetNft.name1'),
            prop: 'tenantName'
        }, 
        {
            width:100,
            label: t('currencySetNft.name2'),
            prop: 'appName',
        },
        {
            width:150,
            label: t('withdrawNft.name10'),
            prop: 'chain_protocol',
        },
        {
            width:150,
            label: t('currencySetNft.name4'),
            prop: 'fromWalletName',
        },
        {
            width:200,
            label: t('otherWallatNft.text6'),
            prop: 'fromAddress',
        },
        {
            width:100,
            label: 'Token ID',
            prop: 'tokenId',
        },
        {
            width:150,
            label: t('withdrawNft.name11'),
            prop: 'itemName',
        },
        {
            width:100,
            label: t('withdrawNft.name5'),
            prop: 'amount',
        },
        {
            width:100,
            label: t('withdrawNft.name12'),
            prop: 'txFee',
        },
        {
            width:200,
            label: t('withdrawNft.name13'),
            prop: 'toAddress'
        },{
            width:100,
            label: t('withdrawNft.name14'),
            prop: 'applyUser',
        },{
            width:170,
            label: t('withdrawNft.name15'),
            prop: 'auditUser',
        },{
            width:150,
            label: t('withdrawNft.name4'),
            prop: 'applyReason',
        },{
            width:150,
            label: t('withdrawNft.name16'),
            prop: 'auditReason',
        },
        {
            width:170,
            label: t('currencySetNft.name6'),
            prop: 'applyTime',
        }, 
        {
            width:170,
            label: t('withdrawNft.name17'),
            prop: 'auditTime',
        },{
            width:170,
            label: t('currencySetNft.name9'),
            prop: 'status',
            dicData: [{ label: t('withdrawNft.name18_1'), value: 0 },
                {label:  t('withdrawNft.name18_2'),value:1},
                {label: t('withdrawNft.name18_3'),value:2}],
        },{
            width:170,
            label: t('withdrawNft.name18'),
            prop: 'doData',
            fixed:'right',
        },{
            width: 80,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
        }]
    }
}